import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: null,
        isFromLogin: false,
    },
    getters: {
        getUser: (s) => {
            return s.user
        },
        getIsFromLogin: (s) => {
            return s.isFromLogin
        }
    },
    mutations: {
        setIsFromLogin: (s, p) => {
            s.isFromLogin = p
        },
        setUser: (s, p) => {
            s.user = p;
        },
        updateProfile(s, p) {
            s.user[p.field] = p.value
        }

    },
    actions: {
        subToOrders(s, p) {
            firebase.firestore().collection('orders').onSnapshot((snap) => {
                var arr = []
                snap.forEach((cat) => {
                    arr.push(cat.data())
                })
                p(arr)
            })
        },

        addService(s, p) {
            return new Promise((resolve, reject) => {
                var ref = firebase.firestore().collection('services').doc()
                var id = ref.id
                firebase.firestore().collection('services').doc(id).set({
                    title: p.title,
                    created_at: new firebase.firestore.Timestamp.fromDate(new Date()),
                    created_by: firebase.auth().currentUser.uid,
                    description: p.description,
                    id: id,
                    orders: 0,
                    total_vendors: 0,
                    category_ref: firebase.firestore().collection('categories').doc(p.category_id),
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteService(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('services').doc(p.id).delete().then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        cancelOrder(s, p) {
            return new Promise((resolve, reject) => {
                var db = firebase.firestore()
                db.collection('orders').doc(p.item.id).update({
                    status: 'cancelled',
                }).then((snap) => {
                    resolve()
                }).catch(err => {
                    reject()
                })
            })
        },
        updateProfile(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(s.getters.getUser.uid).update({
                    name: p.name,
                    address: p.address
                }).then(() => {
                    s.commit('updateProfile', {
                        field: 'name',
                        value: p.name
                    })
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        },



        // categories

        subscribeToCategories(s, p) {
            firebase.firestore().collection('categories').onSnapshot(snap => {
                var arr = []
                snap.forEach(s => {
                    arr.push(s.data())
                })
                p(arr)
            })
        },
        addCategories(s, p) {
            return new Promise((resolve, reject) => {
                var ref = firebase.firestore().collection('categories').doc()
                var id = ref.id
                firebase.firestore().collection('categories').doc(id).set({
                    icon: p.icon,
                    title: p.title,
                    created_at: new firebase.firestore.Timestamp.fromDate(new Date()),
                    created_by: firebase.auth().currentUser.uid,
                    id: id,
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editCategory(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('categories').doc(p.id).update({
                    title: p.title,
                    updated_at: new firebase.firestore.Timestamp.fromDate(new Date()),
                    icon: p.icon,
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteCategory(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('categories').doc(p.id).delete().then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        /// services

        subscribeToServices(s, p) {
            firebase.firestore().collection('services').onSnapshot(snap => {
                var arr = []
                var c = 1
                snap.forEach(s => {
                    var o = s.data()
                    o.counter = c
                    arr.push(o)
                    c++
                })
                p(arr)
            })
        },
        addService(s, p) {
            return new Promise((resolve, reject) => {
                var ref = firebase.firestore().collection('services').doc()
                var id = ref.id
                firebase.firestore().collection('services').doc(id).set({
                    title: p.title,
                    created_at: new firebase.firestore.Timestamp.fromDate(new Date()),
                    created_by: firebase.auth().currentUser.uid,
                    description: p.description,
                    id: id,
                    image: p.image,
                    orders: 0,
                    total_vendors: 0,
                    category_ref: firebase.firestore().collection('categories').doc(p.category_id),
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editService(s, p) {
            return new Promise((resolve, reject) => {
                console.log(p)
                firebase.firestore().collection('services').doc(p.id).update({
                    name: p.name || '',
                    updated_at: new firebase.firestore.Timestamp.fromDate(new Date()),
                    desc: p.desc || '',
                    custom: p.custom || 0,
                    featured: p.featured
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        deleteService(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('services').doc(p.id).delete().then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        //testimonails

        subscribeToTestimonials(s, p) {
            firebase.firestore().collection('testimonials').onSnapshot(snap => {
                var arr = []
                snap.forEach(s => {
                    arr.push(s.data())
                })
                p(arr)
            })
        },
        addTestimonails(s, p) {
            return new Promise((resolve, reject) => {
                var ref = firebase.firestore().collection('testimonials').doc()
                var id = ref.id
                firebase.firestore().collection('testimonials').doc(id).set({
                    id,
                    name: p.name,
                    text: p.text,
                    pos: p.pos,
                    image: p.image,
                    company: p.company,
                    created_at: new firebase.firestore.Timestamp.fromDate(new Date()),
                    created_by: firebase.auth().currentUser.uid,
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editTestimonials(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('testimonials').doc(p.id).update({

                    updated_at: new firebase.firestore.Timestamp.fromDate(new Date()),
                    id: p.id,
                    name: p.name,
                    text: p.text,
                    pos: p.pos,
                    image: p.image,
                    company: p.company,
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteTestimonials(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('testimonials').doc(p.id).delete().then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        ////////users

        subscribeToUsers(s, p) {
            firebase.firestore().collection('users').where('role', '==', 'user').onSnapshot(snap => {
                var arr = []
                snap.forEach(s => {
                    arr.push(s.data())
                })
                p(arr)
            })
        },
        subscribeToVendors(s, p) {
            firebase.firestore().collection('users').where('role', '==', 'vendor').onSnapshot(snap => {
                var arr = []
                snap.forEach(s => {
                    arr.push(s.data())
                })
                p(arr)
            })
        },
        blockUser(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(p.uid).update({
                    status: p.status == 'blocked' ? 'active' : 'blocked'
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        }
    }
})
export default store;