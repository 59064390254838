import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
//mixins 
import globalMixins from './globalMixins'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
const config = {
  apiKey: "AIzaSyDb7DtTCYhh4GRX_AvBEh40JXmLnDnESgU",
  authDomain: "happy-sewa-dynamic.firebaseapp.com",
  databaseURL: "https://happy-sewa-dynamic.firebaseio.com",
  projectId: "happy-sewa-dynamic",
  storageBucket: "happy-sewa-dynamic.appspot.com",
  messagingSenderId: "200036956250",
  appId: "1:200036956250:web:1ac2875ebd5f6ceacc8063",
  measurementId: "G-2ER3QSJR09"
}
firebase.initializeApp(config)
Vue.prototype.$fb = firebase


//register mixin before mount
Vue.mixin(globalMixins)

function mountNow() {
  new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
}
firebase.auth().onAuthStateChanged(user => {
  if (user) {
    //MOUNT NOW
    console.log(user)
    firebase.firestore().collection('users').doc(user.uid).onSnapshot(snap => {
      if (snap.exists) {
        store.commit('setUser', snap.data())
        if (store.getters.getIsFromLogin) {
          store.commit('setIsFromLogin', false)
          router.push('/dashboard')
        }
        mountNow()
      } else {
        alert('no user found ')
        store.commit('setUser', null)
        firebase.auth().signOut()

        mountNow()
      }
    })

  } else {
    //MOUNT NOW
    store.commit('setUser', null)
 
    mountNow()
  }
})
Vue.config.productionTip = false