import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'hash',
    routes: [{
            path: '/',
            component: () => import("@/layouts/Main.vue"),
            redirect: '/dashboard'
        },
        {
            path: '/dashboard',
            meta: {
                isAuth: true
            },
            component: () => import("@/layouts/DashboardLayout.vue"),
            children: [{
                    path: '',
                    name: 'Dashboard',
                    component: () => import('@/pages/Dashboard.vue')
                }, {
                    path: 'manage_service',
                    name: 'service',
                    component: () => import('@/pages/Services.vue')
                }, {
                    path: 'manage_testimonials',
                    name: 'service',
                    component: () => import('@/pages/Testimonials.vue')
                }, {
                    path: 'profile',
                    name: 'profile',
                    component: () => import('@/pages/Profile.vue')
                },
                {
                    path: 'manage_categories',
                    name: 'Categories',
                    component: () => import('@/pages/Categories.vue')
                },
                {
                    path: 'manage_users',
                    name: 'users',
                    component: () => import('@/pages/Users.vue')
                },
                {
                    path: 'manage_orders',
                    name: 'orders',
                    component: () => import('@/pages/Orders.vue')
                },
            ]
        },
        {
            path: '/login',
            component: () => import("@/pages/Login.vue"),
        }
    ],

})
//nav guards
router.beforeEach((to, _from, next) => {
    var isAuth = to.matched.some(record => record.meta.isAuth)
    console.log(isAuth)
    var user = store.getters.getUser
    if (isAuth) {
        if (user) {
            next()
        } else {
            next('/login')
        }
    } else {
        next()
    }
})

export default router